const language = {
    en: {
        title: "Gago games club",
        enter_number: "Enter your number",
        enter_pin: "Enter the Pin Code sent through SMS",
        wait: "Please Wait ...",
        continue: "Continue",
        subscribe: "SUBSCRIBE",
        enterValidNumber: "Please enter a valid number",
        enterValidPin: "Please enter a valid pin code",
        play: "Play",
        games_with: "Games with friends",
        play_games_with: "Play Games with Friends",
        voice_chat: "And Talk Live",
        play_now: "Play Now",
        most_popular: "The Most Popular",
        social_gaming: "Social Gaming Platform",
        leading_publishers: "Games From Leading Publishers",
        games_: "Void Labs | Gamepix | Gameloft",
        terms_conditions: "Terms & Conditions",
        disclaimer: "Disclaimer",
        having_trouble : "Having Trouble?",
        watch_video: "Watch Video",
        see_guide: "See Guide"
    },
    ur: {
        title: "گیگو گیمز کلب",
        enter_number: "اپنا فون نمبر درج کریں۔",
        enter_pin: "ایس ایم ایس کے ذریعے بھیجا گیا پن کوڈ درج کریں",
        wait: "... برائے مہربانی انتظار کریں",
        continue: " جاری رکھیں",
        subscribe: "سبسکرائب",
        enterValidNumber: "براہ مہربانی ایک درست نمبر درج کریں",
        enterValidPin: "براہ کرم ایک درست پن کوڈ درج کریں۔",
        play: "کھیلیں گیمز",
        games_with: "دوستوں کے ساتھ",
        play_games_with: "دوستوں کے ساتھ گیمز کھیلیں",
        voice_chat: "اور بات کریں",
        play_now: "ابھی کھیلیں",
        most_popular: "سب سے زیادہ مقبول",
        social_gaming: "سوشل گیمنگ پلیٹ فارم",
        leading_publishers: "سرکردہ پبلشرز سے گیمز",
        games_: "باطل لیبز | گیم پکس | گیم لوفٹ",
        terms_conditions: "شرائط و ضوابط",
        disclaimer: "ڈس کلیمر",
        having_trouble : "پریشانی ہو رہی ہے ؟",
        watch_video: "ویڈیو دیکھئیے",
        see_guide:"گائیڈ دیکھیں"
        
    },
    ar: {
        title: "نادي ألعاب جاجو",
        enter_number: "رجاء ادخل رقم الجوال        ",
        enter_pin: "أدخل الرقم السري المرسل عبر الرسائل القصيرة",
        wait: "ارجوك انتظر ...",
        continue: "يكمل",
        subscribe: "الإشتراك",
        enterValidNumber: "من فضلك أدخل رقما صالحا",
        enterValidPin: "الرجاء إدخال رمز PIN صالح",
        play: "يلعب",
        games_with: "ألعاب مع الأصدقاء",
        play_games_with: "العب مع أصدقائك",
        voice_chat: "محادثة صوتية",
        play_now: "العب الان",
        most_popular: "الاكثر شهرة",
        social_gaming: "منصة الألعاب الاجتماعية",
        leading_publishers: "ألعاب من كبار الناشرين",
        games_: "مختبرات باطلة | Gamepix | جيم لوفت",
        terms_conditions: "البنود و الظروف",
        disclaimer: "تنصل",
        having_trouble : "واقع في مشكلة",
        watch_video: "شاهد الفيديو",
        see_guide:"انظر الدليل"
    },
};

export default language;