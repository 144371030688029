import { saveUTMParams } from "../helper";
export const LandingPageService = {
    getIP: async () => {
        try {
            const URL = `http://www.geoplugin.net/json.gp`;
            let resp = await fetch(URL);
            resp = await resp.json();
            return resp;
        } catch (error) {
            console.error(error.message);
        }
    },
    getNumberFromHeader: async (url) => {
        try {
            let resp = await fetch(url);
            resp = await resp.json();
            return resp;
        } catch (error) {
            console.error("Error in get number from header", error.message);
            throw new Error(400);
        }
    },
    getJazzHEPin: async (partnerId, serviceId) => {
        try {
            let resp = await fetch(`http://cricket.jazz.com.pk/knect/get_sub_details/?partnerId=${partnerId}&serviceId=${serviceId}`);
            resp = await resp.json();
            return resp;
        } catch (error) {
            console.error("Error in get number from header", error.message);
            throw new Error(400);
        }
    },
    getTelenorHEPin: async (partnerId, serviceId) => {
        try {
            let resp = await fetch(`http://cricwick.net/knect/get_sub_details/?partnerId=${partnerId}&serviceId=${serviceId}`);
            resp = await resp.json();
            return resp;
        } catch (error) {
            console.error("Error in get number from header", error.message);
            throw new Error(400);
        }
    }
}