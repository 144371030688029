import { v1 as uuidv1 } from "uuid";

const CDN_URL = "https://dox85mdhc5v5a.cloudfront.net/";
const CDN_URL_ASSETS = CDN_URL + "landingPageAssets/lp/";

const urlParams = new URLSearchParams(window.location.search);
// DOUBLE_CLICK, SINGLE_CLICK, OTP

const countries = ["pk", "kw", "sa", "iq", "ae"];
const languages = ["en", "ur", "ar"];
const fg = ["sa", "pk"]; // fg = freegames allow regions
const allPaths = window.location.pathname.split("/");

const sendSubscribeEventOnAnalytics = () => {
  window.gtag("event", "subscribe", {});
}

const sendChargeEventOnAnalytics = () => {
  window.gtag("event", "charged", {});
}

export const findCaseInsensitive = (array, value) => {
  return array.find((item) => value.toLowerCase() === item.toLowerCase());
};

export const getUserCountry = () => {
  //return array.length >= 3 ? (!array[2].toLowerCase() ? "pk" : array[2].toLowerCase()) : "pk";
  for (let j = 0; j < countries.length; j++) {
    const item = findCaseInsensitive(allPaths, countries[j]);
    if (item) {
      return item;
    }
  }
  return "pk";
};

const getDefaultUTM = () => {
  return getUserCountry() === "pk" ? "183" : "186";
};
// window.utm = {
let tracking_code = urlParams.get("utm_content")
  ? urlParams.get("utm_content")
  : uuidv1();
let compaign_name = urlParams.get("utm_campaign")
  ? urlParams.get("utm_campaign")
  : urlParams.get("cid")?urlParams.get("cid"):"";
let medium = urlParams.get("utm_medium")
  ? urlParams.get("utm_medium")
  : "Khaleef";
let utm_source = urlParams.get("utm_source")
  ? urlParams.get("utm_source")
  : getDefaultUTM();
let antiFraudUniqId = "";
export let DirectServiceId_From_Url = "";
export let TransactionId_From_Url = "";
export let TrackId_From_Url = "";
export let NUM_FROM_URL = undefined;
export let PARTNERID_From_Url = undefined;
// subscriptionNo: urlParams.get("subscriptionNo")
// }

export const isKW = () =>{
  return findCaseInsensitive(allPaths, "kw")
}

export const isIQ = () =>{
  return findCaseInsensitive(allPaths, "iq")
}

if (isIQ() || isKW()) {
  if (urlParams.get("msisdn")) {
    NUM_FROM_URL = urlParams.get("msisdn");
  }
  if (urlParams.get("tid")) {
    TransactionId_From_Url = urlParams.get("tid");
  }
  if (urlParams.get("track_id")) {
    const TrackId = urlParams.get("track_id");
    TrackId_From_Url = TrackId;
    const splitedData = TrackId.split("_");
    if (splitedData.length > 0) {
      DirectServiceId_From_Url = splitedData[splitedData.length - 1];
      tracking_code = splitedData[splitedData.length - 2];
      medium = splitedData[splitedData.length - 3];
      utm_source = splitedData[splitedData.length - 4];
      PARTNERID_From_Url = splitedData[splitedData.length - 5];

      if (tracking_code.length == 0) {
        tracking_code = uuidv1();
      }
    }
  }
}

export const hasNumInUrl = () => {
  return NUM_FROM_URL ? true : false;
};

export const getCDNUrl = (filename) => {
  return CDN_URL_ASSETS + filename;
};
export const getTrackingCode = () => {
  return tracking_code;
};
export const getUTMMediun = () => {
  return medium;
};
export const getUTMSource = () => {
  return utm_source;
};
export const getLastPathItem = (thePath) => {
  return thePath && thePath.substring(thePath.lastIndexOf("/") + 1);
};

export const getAllPathsInUrl = (url) => {
  return allPaths;
};

export const getUserLanguage = () => {
  for (let j = 0; j < languages.length; j++) {
    const item = findCaseInsensitive(allPaths, languages[j]);
    if (item) {
      return item;
    }
  }
  return "en";
};

export const isFreeGamesPage = () => {
  for (let j = 0; j < fg.length; j++) {
    const userCountry = getUserCountry();
    const freeGamesAllowedInCountry = fg.includes(userCountry);
    const isFreeGamesAvailable = findCaseInsensitive(allPaths, "fg");
    if (freeGamesAllowedInCountry && isFreeGamesAvailable) {
      return true;
    }
  }
  return false;
};

export const getMatchedValue = (array, value, defalt) => {
  for (let i = 0; i < array.length; i++) {
    for (let j = 0; j < value.length; j++) {
      if (array[i].toLowerCase() === value[j].toLowerCase())
        return value[j].toLowerCase();
    }
  }
  return defalt;
};
export const getMatchedItemFromResponse = (response, items) => {
  for (let i = 0; i < items.length; i++) {
    if (items[i] in response) return response[items[i]];
  }
};
export const saveUTMParams = async (payload, event_name, msisdn,service_id="") => {

  if (event_name == "subscribe") {
    sendSubscribeEventOnAnalytics()
  } else if (event_name == "charged") {
    sendChargeEventOnAnalytics()
  }
  const new_compaign_name=service_id?`${compaign_name}_${service_id}`:compaign_name
  let url =
    "https://k8s.cricwick.net/khaleef-attribution/api/v1/attributions/mark_event?event_name=" +
    event_name +
    "&compaign_id=" +
    getCompaignId() +
    "&tracking_code=" +
    tracking_code +
    "&compaign_name=" +
    new_compaign_name +
    "&medium=" +
    medium +
    "&msisdn=" +
    msisdn +
    "&gclid=" +
    getParametrByName("gclid");

  if (findCaseInsensitive(allPaths, "iq")) {
    fetch("https://app-dev.gago.games/api/v1/landingPageTest", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        text: url,
      }),
    });
  }

  await fetch(url);
  // console.log(utmResponse);
};

export function getParametrByName(name) {
  var url_string = window.location.href;
  var url = new URL(url_string);
  var c = url.searchParams.get(name);

  return c ? c : "";
}

export const getErrorObj = (message) => {
  return {
    status: false,
    message: message,
  };
};

export const getNumbervalidObj = (initial, prefix, number) => {
  return {
    status: true,
    initial: initial,
    prefix: prefix,
    number: number,
  };
};

export const ValidateUTMSource = () => {
  const utms = {
    2: ["183", "186", "190", "201", "205", "63", "64", "67"],
    6: ["185"],
    10: ["10"],
    11: ["187", "11"],
    12: ["210"],
    14: ["184"],
    15: ["208"],
    20: ["188"],
  };
  let p_id = urlParams.get("partner_id") ? urlParams.get("partner_id") : "2";
  if (PARTNERID_From_Url) {
    p_id = PARTNERID_From_Url;
  }
  for (let i = 0; i < utms[p_id].length; i++) {
    if (utms[p_id][i] === utm_source) return true;
  }
  return false;
};

export const isPartnerAllowedToRedirect = () => {

  const allowedRedirectionForPartners = ['2']

  const pid = PARTNERID_From_Url ? PARTNERID_From_Url : getParametrByName("partner_id")

  return allowedRedirectionForPartners.includes(pid) == true
}

const getCompaignId = () => {
  return utm_source;
};
export const saveChargedEvent = async (response, msisdn) => {
  if ("is_charged" in response && response["is_charged"] === 1) {
    await saveUTMParams({}, "charged", msisdn);
  } else {
    if ("data" in response && response["data"].is_charged === 1) {
      await saveUTMParams({}, "charged", msisdn);
    } 
  }
};

export const reportGtagConversion = async (response) => {
  if (findCaseInsensitive(allPaths, "iq")) {
    window.gtag_report_conversion3();
    return;
  }

  if (findCaseInsensitive(getAllPathsInUrl(), "gadsp")) {
    if ("is_charged" in response && response["is_charged"] === 1) {
      window.gtag_report_conversion2();
    }
  } else {
    window.gtag_report_conversion();
  }
};

export const redirectOnGago = (number, medium) => {
  window.location.href = `https://gago.games/?subscriptionNo=${number}&utm_campaign=internal&utm_medium=${medium}`;
  return;
};

export const redirectOnZGames = (number) => {
  window.location.href = `http://zgames.mobi/?q=${number}`;
  return;
};

export const setAntiFraudUniqId = (id) => {
  antiFraudUniqId = id;
};

export const getAntiFraudUniqId = () => {
  let antiFraudId = document.getElementById("AntiFraudIdDiv")?.innerHTML;
  if (antiFraudId && antiFraudId.length > 0) {
    return antiFraudId;
  }
  return antiFraudUniqId;
};

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));
