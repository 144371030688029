import { useState, useEffect } from "react";
import React from "react";
import GameCloseBar from "./GameCloseBar";

const Games = () => {
  // GamePlay GamePlay function
  const [isGamePlay, setisGamePlay] = useState(false);
  const [selectedGameId, setSelectedGameId] = useState(-1);

  const toggleGamePlay = (data) => {
    setisGamePlay(true);
    setSelectedGameId(data - 1);
    // console.log(data);
  };
  const closeHandler = () => {
    setisGamePlay(false);
  };
  ////fetching json and storing into data
  const [data, setData] = useState(null);

  const showIframe = () => {
    if (selectedGameId < 0)
      return (<> </>);

    const game = data[selectedGameId];
    return (
      <>
        <iframe
          className="GamePlayBox"
          src={game.url}
          height="550"
          width="480"
        >
        </iframe>
          <GameCloseBar handleClose={closeHandler} />
      </>
    );
  };

  useEffect(() => {
    fetch("./freeGames.json")
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        // store Data in State Data Variable
        setData(data);
      })
      .catch(function (err) {
        console.log(err, " error");
      });
  }, []);
  return (
    <div className="games">
      {
        isGamePlay && selectedGameId > -1 && (
          showIframe()
        )
      }
      {
        // use data State Variable For Get Data Use JavaScript Map Mathod
        data
          ? data.map(function (data) {
              return (
                <>
                  <div className="centerClass">
                    <a onClick={() => toggleGamePlay(data.id)} key={data.id}>
                      <img className="gameImages" src={data.thumbnail} />
                    </a>
                  </div>
                </>
              );
            })
          : ""
      }
    </div>
  );
};
export default Games;
