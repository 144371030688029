import React from "react";
import Games from "./Games";
import language from "../translations";

const GameHeader = ({ lang }) => {
  return (
    <>
      <div className="centerClass">
        <div className="game-p">{language[lang].most_popular}</div>
        <div className="game-header">{language[lang].social_gaming}</div>
      </div>
      <Games />
    </>
  );
};
export default GameHeader;
