import React from "react";
import language from "../translations";

const GameFooter = ({ lang }) => {
  return (
      <div className="centerClass">
        <div className="game-p">{language[lang].leading_publishers}</div>
        <div className="game-footer">{language[lang].games_}</div>
      </div>
  );
};
export default GameFooter;
