import React from "react";
import './GameCloseBar.css'

const GamePlay = props => {
  return (
    <div className="GamePlay-GamePlayBox">
        <div className="GamePlayCloseIcon" onClick={props.handleClose}>x</div>
    </div>
  );
};
 
export default GamePlay;