import React, { useReducer, useEffect, Fragment, Suspense, lazy } from "react";
import "./App.css";
// import Loader from "./loader/Loader";
import { LandingPageService } from "./Service/LandingPageService";
import {
  getUserLanguage,
  getUserCountry,
  getMatchedItemFromResponse,
  ValidateUTMSource,
  saveUTMParams,
  isFreeGamesPage,
  getParametrByName,
  getAllPathsInUrl,
  getUTMSource,
  hasNumInUrl,
  NUM_FROM_URL,
  isPartnerAllowedToRedirect,
  isIQ,
  isKW,
} from "./helper";
import GameHeader from "./GamesBlock/GameHeader";
import GameFooter from "./GamesBlock/GameFooter";
import useState from "react-usestateref";
import language from "./translations";

const HeaderBarComponent = lazy(() => import("./header/HeaderBar" /* b */));
const HeaderLogoComponent = lazy(() => import("./header/HeaderLogo" /* l */));
const EnterNumberComponent = lazy(() =>
  import("./EnterNumber/EnterNumber" /* n */)
);
const EnterPinComponent = lazy(() => import("./EnterPin/EnterPin" /* pn */));
// const PriceTextComponent = lazy(() =>
//   import("./price-text/PriceText" /* pr */)
// );

const initialState = {
  lang: "en",
  error: "",
  loading: false,
  number: "",
  pin: "",
  isHE: false,
  loadingHE: false,
  ip: "",
  pinSent: false,
  isHttps: false,
  country: undefined,
  telco: undefined,
  headerClickCount: 0,
  flow: "",
  utmValidationStatus: false,
  configuration: undefined,
  services: [],
  freeGames: false,
  isNumberPopulatedWithHE: false,
};
function reducer(state, action) {
  return { ...state, [action.type]: action.payload };
}
function App() {
  const [state, dispatch] = useReducer(reducer, initialState);

  // -2 or less = default, -1 = ho header calls available, 0 will represent no header found
  const [
    availableHeaderCount,
    setAvailableHeaderCount,
    availableHeaderCountRef,
  ] = useState(-2);

  const [
    isHeaderFound,
    setIsHeaderFound,
    isHeaderFoundRef,
  ] = useState(false);

  useEffect(() => {
    let allPaths = getAllPathsInUrl(window.location.pathname);
    if (
      window.location.protocol === "https" &&
      !state.isHttps &&
      !allPaths.includes("ep")
    ) {
      dispatch({ type: "isHttps", payload: true });
    }
    if (!ValidateUTMSource()) {
      dispatch({ type: "utmValidationStatus", payload: false });
      console.error("Error UTM VALIDATION FAILS");
      return;
    }
    dispatch({ type: "utmValidationStatus", payload: true });
    dispatch({ type: "country", payload: getUserCountry() });
    dispatch({ type: "lang", payload: getUserLanguage() });
    dispatch({ type: "freeGames", payload: isFreeGamesPage() });
  }, [state.isHttps]);

  useEffect(() => {
    if (state.country)
      (async function () {
        let config = await import("./Configuration/" + state.country);
        if (config) {
          config = config.default[state.country];
          let validServices = config.getValidServices();
          // console.log(validServices);
          dispatch({ type: "configuration", payload: config });
          dispatch({ type: "services", payload: validServices });
          dispatch({ type: "loadingHE", payload: true });
          await saveUTMParams(window.utm, "install", "");
          const headersCount = config.services
            .filter((service) => validServices.includes(service.SERVICE_ID))
            .filter((telco) => telco.header).length;
          setAvailableHeaderCount(headersCount > 0 ? headersCount : -1);
          // console.log("Fiilter count: ", availableHeaderCountRef.current)
          // special case to fetch all details from query params
          if (hasNumInUrl() && isIQ()) {
            let telco = config.services.filter((service) =>
              validServices.includes(service.SERVICE_ID)
            );
            telco = telco.length > 0 ? telco[0] : undefined;
            if (!telco) {
              return;
            }
            dispatch({ type: "isHE", payload: true });
            dispatch({ type: "number", payload: NUM_FROM_URL });
            dispatch({ type: "flow", payload: "SINGLE_CLICK" });
            dispatch({ type: "telco", payload: telco });
            dispatch({ type: "loadingHE", payload: false });
            window.gtag("event", "header_found", {
              msisdn: NUM_FROM_URL,
              flow: "SINGLE_CLICK"
            });
            saveUTMParams(
              window.utm,
              "install",
              NUM_FROM_URL ? NUM_FROM_URL : ""
            );
          }else if (hasNumInUrl() && isKW()) {
            let telco = undefined;
            let response = config.validateNum(NUM_FROM_URL);
            if (!response.status) {
              dispatch({ type: "error", payload: language[state.lang].enterValidNumber });
            } else {
        
              telco = config.services
                .filter((service) => validServices.includes(service.SERVICE_ID))
                .find((telco) => telco.PREFIX.includes(response.prefix));
        
              if (telco) {
                dispatch({ type: "telco", payload: telco });
                dispatch({ type: "number", payload: response.number });
              } else {
                dispatch({ type: "error", payload: language[state.lang].enterValidNumber });
                return
              }
            }
            if (!telco) {
              return;
            }
            dispatch({ type: "isHE", payload: false });
            dispatch({ type: "flow", payload: "OTP" });
            dispatch({ type: "loadingHE", payload: false });
            window.gtag("event", "header_found", {
              msisdn: NUM_FROM_URL,
              flow: "OTP"
            });
            saveUTMParams(
              window.utm,
              "install",
              NUM_FROM_URL ? NUM_FROM_URL : ""
            );
          } else if (isKW() || isIQ() && (!hasNumInUrl())) {
            window.gtag("event", "wifi_traffic", {});
          } else {
            config.services
              .filter((service) => validServices.includes(service.SERVICE_ID))
              .forEach((telco, i) => {
                if (telco.header) {
                  if (!telco.header.getNumberUrl) {
                    return;
                  }
                  LandingPageService.getNumberFromHeader(
                    telco.header.getNumberUrl(),
                    i
                  ).then(
                    (NFHResponse) => {
                      if (NFHResponse?.status === 1) {
                        setIsHeaderFound(true)
                        dispatch({
                          type: "isHE",
                          payload:
                            NFHResponse.data.flow === "OTP" ? false : true,
                        });
                        if (NFHResponse.data.flow === "OTP" && getUserCountry() === "pk") {
                          dispatch({
                            type: "isNumberPopulatedWithHE",
                            payload: true,
                          });
                        }
                        let number = getMatchedItemFromResponse(
                          NFHResponse.data,
                          ["number", "msisdn"]
                        );
                        dispatch({ type: "telco", payload: telco });
                        dispatch({
                          type: "flow",
                          payload: NFHResponse.data.flow,
                        });
                        dispatch({
                          type: "token",
                          payload: NFHResponse.data.token,
                        });
                        dispatch({ type: "number", payload: number });
                        window.gtag("event", "header_found", {
                          ...NFHResponse,
                        });
                        saveUTMParams(
                          window.utm,
                          "install",
                          number ? number : ""
                        );
                      }
                      checkAndUpdateHeaderCount();
                    },
                    () => {
                      // console.log("error is called")
                      checkAndUpdateHeaderCount();
                    }
                  );
                }
              });
          }
          LandingPageService.getIP().then((getIp) => {
            dispatch({ type: "ip", payload: getIp?.geoplugin_request });
          });
        }
      })();
    // console.log(state.ip)
  }, [state.country]);

  const checkAndUpdateHeaderCount = () => {
    if (availableHeaderCountRef.current > 0) {
      setAvailableHeaderCount(availableHeaderCountRef.current - 1);
    }
    if (availableHeaderCountRef.current === 0) {
      dispatch({ type: "loadingHE", payload: false });
      if (!isHeaderFoundRef) {
        window.gtag("event", "wifi_traffic", {});
      }
    }
  };

  // useEffect(() => {
  //   if (state.telco && state.telco.pinFlow.isMcpEnabled) {
  //     //load MCP script here
  //     // let script = state.telco.pinFlow.mcpScript();
  //     if(!state.pinSent)
  //     state.telco.pinFlow.mcpScript(1,state.trackingCode);
  //     else
  //     state.telco.pinFlow.mcpScript(2,state.trackingCode);
  //     // eval(script);
  //   }
  //   // else
  //   //   console.log("else load MCP SCRIPT");
  // }, [state.telco, state.pinSent]);

  const addParameterToURL = (url, param) => {
    if (window.os === "AndroidOS")
      url += (url.split("&referrer=")[1] ? "%26" : "&referrer=") + param;
    else url += (url.split("?")[1] ? "&" : "?") + param;
    return url;
  };
  useEffect(() => {
    // console.log("count use effect: ", availableHeaderCountRef.current)
    if (!isPartnerAllowedToRedirect()) {
      return
    }

    let allPaths = getAllPathsInUrl(window.location.pathname);
    if (
      availableHeaderCountRef.current == 0 &&
      getUserCountry() === "pk" &&
      !allPaths.includes("ep")
    ) {
      // count has been changed to 0 becasue no header found from API calls
      // perform any actions need to be done if no headers found
      // http://gago.games/digital/pk?
      // utm_source=183&utm_medium=Mob_TopHead_Banner&utm_campaign=Cricwick&partner_id=2
      const campaign = getParametrByName("utm_campaign");
      let source = getUTMSource();
      const medium = getParametrByName("utm_medium");

      source = source == 183 ? 182 : source;

      let webRedirectUrl = "https://gago.games/login";
      // let androidRedirectUrl =
      //   "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.gago.multiplayergames";

      if (campaign && campaign.length > 0) {
        webRedirectUrl = addParameterToURL(
          webRedirectUrl,
          window.os === "AndroidOS"
            ? `utm_campaign%3D${campaign}`
            : `utm_campaign=${campaign}`
        );
      }

      if (source) {
        webRedirectUrl = addParameterToURL(
          webRedirectUrl,
          window.os === "AndroidOS"
            ? `utm_source%3D${source}`
            : `utm_source=${source}`
        );
      }

      if (medium && medium.length > 0) {
        webRedirectUrl = addParameterToURL(
          webRedirectUrl,
          window.os === "AndroidOS"
            ? `utm_medium%3D${medium}`
            : `utm_medium=${medium}`
        );
      }

      // console.log("HE: ", state.isHE)

      // if (!state.isHE) {
      //   setAvailableHeaderCount(-2);

      //   if (window.os === "AndroidOS") {
      //     //`https://play.google.com/store/apps/details?id=com.gago.multiplayergames&referrer=utm_source%3D${source}%26utm_medium%3D${medium}%26utm_campaign%3D${campaign}`;
      //     window.location = androidRedirectUrl;
      //     window.gtag("event", "redirected_on_android");

      //     return;
      //   }
      //   // window.location.href = webRedirectUrl;
      //   return;
      // }
    }
  }, [availableHeaderCountRef.current]);

  useEffect(() => {
    if (!state.country, !state.ip) {
      return
    }
    if (state.pinSent && state.telco && state.telco.pinFlow.isMcpEnabled) {
      //load MCP script here
      let script = state.telco.pinFlow.mcpScript(2,state.ip);
      eval(script);
    } else {
      //   console.log("else load MCP SCRIPT");
      let validServices = state.configuration?.getValidServices();
      state.configuration?.services
        .filter((service) => validServices.includes(service.SERVICE_ID))
        .forEach((telco, i) => {
          if (telco.pinFlow.isMcpEnabled) {
            telco.pinFlow.mcpScript(1, state.ip);
          }
        });
    }
  }, [state.ip, state.pinSent]);

  const emptyView = () => {
    return <div></div>;
  };
  const mainView = () => {
    return (
      <div className="container">
        <div className={state.lang === "en" ? "en" : "ur"}>
          <Fragment>
            <div>
              <Suspense fallback={<></>}>
                <HeaderBarComponent
                  dispatch={dispatch}
                  lang={state.lang}
                  languages={
                    state.configuration ? state.configuration.languages : []
                  }
                />
              </Suspense>
            </div>
            <div style={{ minHeight: "22%" }}>
              <Suspense fallback={<></>}>
                <HeaderLogoComponent lang={state.lang} />
              </Suspense>
            </div>
            <div>
              <Suspense fallback={<></>}>
                <section>
                  {!state.pinSent && (
                    <EnterNumberComponent state={state} dispatch={dispatch} />
                  )}
                  {state.pinSent && (
                    <EnterPinComponent state={state} dispatch={dispatch} />
                  )}
                </section>
              </Suspense>
            </div>
            <div
              className={
                state.lang === "en"
                  ? "price-points-normal"
                  : "price-points-normal ur"
              }
            >
              <Suspense fallback={<></>}>
                <div>
                  {state.configuration
                    ? state.configuration.price_points[state.lang]
                      ? state.configuration.price_points[state.lang]()
                      : ""
                    : ""}
                </div>
              </Suspense>
            </div>
            <div>
              <Suspense fallback={<></>}>
                {state.freeGames && (
                  <>
                    <GameHeader
                      dispatch={dispatch}
                      lang={state.lang}
                      freeGames={state.freeGames}
                    />
                    <GameFooter
                      dispatch={dispatch}
                      lang={state.lang}
                      freeGames={state.freeGames}
                    />
                  </>
                )}
                {!state.freeGames && <div className="not-free-games-footer" />}
              </Suspense>
            </div>
          </Fragment>
        </div>
      </div>
    );
  };

  return (
    <>
      {state.isHttps
        ? emptyView()
        : state.utmValidationStatus
        ? mainView()
        : emptyView()}
    </>
  );
}

export default App;
